<template>
  <div class="app app-index v">
    <div class="v c header no-flex">
      <div class="avatar gap-f-10 no-flex" :style="{backgroundImage: `url(${avatar})`}"></div>
      <div class="fs-1x bold">{{user.nickName}}</div>
      <div class="fc-c gap-small bold">{{greeting}}</div>
    </div>
    <template v-if="analysis">
      <div class="h no-flex visible">
        <count-to title="项目数" class="flex item" :class="selected === 'project' ? 'is-active' : ''" :end-val="analysis.all.project_new" :value-class="`fs-huge bold ${analysis.all.project_new > 0 ? '' : 'fc-g'}`" @click.native="selected = 'project'" />
        <count-to title="任务数" class="flex item" :class="selected === 'task' ? 'is-active' : ''" :end-val="analysis.all.task_new" :value-class="`fs-huge bold ${analysis.all.task_new > 0 ? '' : 'fc-g'}`" @click.native="selected = 'task'" />
        <count-to title="实验数" class="flex item" :class="selected === 'experiment' ? 'is-active' : ''" :end-val="analysis.all.exp_new" :value-class="`fs-huge bold ${analysis.all.exp_new > 0 ? '' : 'fc-g'}`" @click.native="selected = 'experiment'" />
        <count-to title="风险数" class="flex item" :class="selected === 'risk' ? 'is-active' : ''" :end-val="analysis.all.risk_new - analysis.all.risk_close" :value-class="`fs-huge bold ${analysis.all.risk_new - analysis.all.risk_close > 0 ? 'fc-e' : 'fc-g'}`" @click.native="selected = 'risk'" />
      </div>
      <div class="flex scroll-able-y bc-w contents">
        <template v-if="selected === 'project'">
          <div class="h sb">
            <count-to title="已完成" :end-val="analysis.all.project_finish" title-class="op-60" :value-class="`fs-huge bold ${analysis.all.project_finish > 0 ? 'fc-s' : 'fc-g'}`" />
            <div class="sep"></div>
            <count-to title="今日新增" :end-val="analysis.today.project_new" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.project_new > 0 ? '' : 'fc-g'}`" />
            <count-to title="今日完成" :end-val="analysis.today.project_finish" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.project_finish > 0 ? 'fc-s' : 'fc-g'}`" />
          </div>

          <van-button round type="info" class="nav-button" size="large" @click="toProject">查看项目概况</van-button>
        </template>

        <template v-else-if="selected === 'task'">
          <div class="h sb">
            <count-to title="已完成" :end-val="analysis.all.task_finish" title-class="op-60" :value-class="`fs-huge bold ${analysis.all.project_finish > 0 ? 'fc-s' : 'fc-g'}`" />
            <div class="sep"></div>
            <count-to title="今日新增" :end-val="analysis.today.task_new" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.task_new > 0 ? '' : 'fc-g'}`" />
            <count-to title="今日完成" :end-val="analysis.today.task_finish" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.task_finish > 0 ? 'fc-s' : 'fc-g'}`" />
          </div>

          <van-button round type="info" class="nav-button" size="large" @click="toMyProjectTask">查看我的任务</van-button>
        </template>

        <template v-else-if="selected === 'experiment'">
          <div class="h sb">
            <count-to title="已完成" :end-val="analysis.all.exp_finish" title-class="op-60" :value-class="`fs-huge bold ${analysis.all.exp_finish > 0 ? 'fc-s' : 'fc-g'}`" />
            <div class="sep"></div>
            <count-to title="今日新增" :end-val="analysis.today.exp_new" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.exp_new > 0 ? '' : 'fc-g'}`" />
            <count-to title="今日完成" :end-val="analysis.today.exp_finish" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.exp_finish > 0 ? 'fc-s' : 'fc-g'}`" />
          </div>
        </template>

        <template v-else-if="selected === 'risk'">
          <div class="h sb">
            <count-to title="已处置" :end-val="analysis.all.risk_close" title-class="op-60" :value-class="`fs-huge bold ${analysis.all.risk_close > 0 ? 'fc-s' : 'fc-g'}`" />
            <div class="sep"></div>
            <count-to title="今日新增" :end-val="analysis.today.risk_new" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.risk_new > 0 ? '' : 'fc-g'}`" />
            <count-to title="今日处置" :end-val="analysis.today.risk_close" title-class="op-60" :value-class="`fs-huge bold ${analysis.today.risk_close > 0 ? 'fc-s' : 'fc-g'}`" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selected: "project",
      analysis: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    avatar() {
      return (
        (this.user && this.user.avatarName) || "/images/default-avatar.png"
      );
    },

    greeting() {
      const now = new Date().getHours();
      if (now < 6 || now > 21) {
        return "夜深了，欢迎回来";
      } else if (now < 12) {
        return "上午好！欢迎回来";
      } else if (now < 14) {
        return "上午好！欢迎回来";
      } else if (now < 18) {
        return "下午好！欢迎回来";
      } else {
        return "晚上好！欢迎回来";
      }
    },
  },
  methods: {
    loadAnalysis() {
      let now = new Date();
      Promise.all([
        request({
          url: "api/projectAnaly",
          method: "get",
          params: {
            beg: "2000-01-01 00:00:00",
            end: now.format("yyyy-MM-dd 23:59:59"),
          },
        }),
        request({
          url: "api/projectAnaly",
          method: "get",
          params: {
            beg: now.format("yyyy-MM-dd 00:00:00"),
            end: now.format("yyyy-MM-dd 23:59:59"),
          },
        }),
      ]).then((arr) => {
        this.analysis = {
          all: arr[0],
          today: arr[1],
        };
      });
    },
    toProject() {
      this.$router.push("/project");
    },
    toMyProjectTask() {
      this.$router.push("/project/task/mine");
    },
  },
  mounted() {
    this.loadAnalysis();
  },
};
</script>

<style lang="scss">
.app-index {
  > .header {
    background-color: white;
    padding: 20px 0 50px;
    border-radius: 0 0 50vw 50vw/0 0 25px 25px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;

    .avatar {
      width: 8rem;
      height: 8rem;
      background-color: var(--color-white);
      border-radius: 50%;
      background-repeat: center center;
      background-size: cover;
    }
  }

  .item {
    border-radius: 10px 10px 0 0;
    border-bottom: solid var(--border-color-base) 2px;
    padding: 20px 0;
    transition: all 0.3s;
    &.is-active {
      background-color: var(--color-white);
      box-shadow: 0 0 0 2px var(--border-color-base),
        0 0 30px rgba(0, 0, 0, 0.05);
      border-bottom-color: transparent;
    }
  }

  .contents {
    padding: 40px 20px;
  }

  .sep {
    width: 1px;
    background-color: var(--border-color-base);
  }

  .nav-button {
    width: 100%;
    margin-top: 40px;
  }
}
</style>